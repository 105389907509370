<template>
  <div class="container">
  <div class="table-info">KRW/USDT: {{KRWUSD}} |
    <input type="radio" id="horizontal" value="horizontal" v-model="tableDirection">
    <label for="horizontal">가로</label>
    <input type="radio" id="vertical" value="vertical" v-model="tableDirection">
    <label for="vertical">세로</label>
    <input type="radio" id="vertical" value="folding" v-model="tableDirection">
    <label for="folding">폴딩</label>
  </div>

    <!-- horizontal table -->
    <div class="table-wrap horizontal" id='coin-table' 
    v-if="tableDirection==='horizontal'">
      <div class="table-header">
        <div class="table-header-item">Ticker</div>
        <div v-for="(value, key, index) in chartVal['dataJson']['upbit']" 
        :key=key class="table-header-item" :id="'header-' + key"
        v-on:click="sortTable(index)"
        >{{key}}</div>
      </div>

      <div v-for="(exchange, exchangeKey) in chartVal['dataJson']" 
      :key=exchangeKey class="table-row">
        <div class="table-row-item" :id="'exchanger-' + exchangeKey">
          {{exchangeKey}}
        </div>
        <div v-for="(coin, coinKey) in chartVal['dataJson']['upbit']" 
        :key="coinKey + exchangeKey" class="table-row-item coinprice" :id="'row-' + exchangeKey + coinKey"
        >
          {{FormatPrice(chartVal['dataJson'][exchangeKey][coinKey])}}
        </div>
      </div>
    </div>

    <!-- vertical table -->
    <div class="table-wrap vertical" id='coin-table' 
    v-if="tableDirection==='vertical'">
      <div class="table-header">
        <div class="table-header-item">Ticker</div>
        <div v-for="(value, key, index) in chartVal['dataJson']" 
        :key=key class="table-header-item" :id="'header-' + key"
        v-on:click="sortTable(index)"
        >{{key}}</div>
      </div>

      <div v-for="(coinPrice, coinKey) in chartVal['dataJson']['upbit']" 
      :key=coinKey class="table-row">
        <div class="table-row-item coinTicker" :id="'ticker-' + coinKey">
          {{coinKey}}
        </div>
        <div v-for="(exchange, exchangeKey) in chartVal['dataJson']" 
        :key="coinKey + exchangeKey" class="table-row-item coinprice" :id="'row-' + exchangeKey + coinKey">
          {{FormatPrice(chartVal['dataJson'][exchangeKey][coinKey])}}
        </div>
      </div>
    </div>

    <!-- folding table -->
    <div class="table-wrap foldable" id='coin-table' 
    v-if="tableDirection==='folding'">
      <div v-for="(coinPrice, coinKey) in chartVal['dataJson']['upbit']" 
      :key=coinKey class="table-row">
        <div class="table-row-item tickerbox" :id="'tickerbox-' + coinKey">
        <div class="coinTicker" :id="'ticker-' + coinKey">
          {{coinKey}}
        </div>
        </div>

        <CoinTableRow :pricelist="coinPrices(coinKey)" :KRWUSD="this.KRWUSD" :ticker="coinKey"/>

      </div>
    </div>
  </div>

    <div id="table_div"></div>
    <div id="table_div_pivot"></div>
    <div id="table_div_pivot2"></div>
</template>

<script>

import CoinTableRow from '@/components/CoinTableRow.vue'

import CoinService from '@/services/CoinService.js'
import CustomMath from '@/services/CustomMath.js'
import styles from "@/assets/css/style.css"
import { watchEffect } from 'vue'

import TableSorting from '@/assets/js/TableSorting.js'

export default {
  name: 'CoinTable',
  components: {
    CoinTableRow
  },
  props: {

  },
  data () {
    return {
      tableDirection: 'folding',
      chartVal: {
        dataJson: {
          'upbit': {'BTC': 0},
         }, //save coin data as json for pivot chart, keys: coins
        data: null,
        columns: null,
        refPeriod: 30000
      },
      exchanger: {
        upbit: null,
        coinbase: null,
        bitthumb: null,
        binance: null,
        coinOne: null,
        houbi: null,
        kraken: null,
        gate: null,
      },
      KRWUSD: 1,
    }
  },
  mounted () {
  },
  methods: {
    coinPrices (ticker) {
      var priceList = {};
      const exchangers = Object.keys(this.chartVal.dataJson);

      for (var i=0; i < exchangers.length; i++){
        priceList[exchangers[i]] = this.chartVal.dataJson[exchangers[i]][ticker];
      }

      return priceList;

    },
    sortTable(n) {
      TableSorting.sortTable(n);
    },
    FormatPrice(pair) {
      return TableSorting.FormatPrice(pair, this.KRWUSD);
    },
    getCurrencyRate(){
      var global = this;
      CoinService.getUSDRate()
      .then(response =>{
        global.KRWUSD = response.data;
      })

    },
    //coin 데이터 첫 모음
    getCoinInfo () {
      var global = this;
      CoinService.upbitGetCoinsAsync()
      .then(response => {
        var upbitCoins = response
        const keys = Object.keys(upbitCoins)
        global.chartVal.dataJson['upbit'] = response;
        global.exchanger.upbit = true

        CoinService.binanceGetCoinAsync()
          .then(response => {
            var binanceCoins = response
            global.chartVal.dataJson['binance'] = response.Json;
            // global.KRWUSD = response.rate;
            global.exchanger.binance = true
          })

        CoinService.houbiGetCoinAsync()
          .then(response => {
            var houbiCoins = response
            global.chartVal.dataJson['houbi'] = response;
            global.exchanger.houbi = true
          })

        CoinService.coinOneGetCoinAsync()
          .then(response => {
            var coinOneCoins = response
            global.chartVal.dataJson['coinOne'] = response;
            global.exchanger.coinOne = true
          })

        CoinService.bitthumbGetCoinAsync()
          .then(response => {
            var bitthumbCoins = response
            global.chartVal.dataJson['bitthumb'] = response;
            global.exchanger.bitthumb = true
          })

        CoinService.coinbaseGetCoinAsync()
          .then(response => {
            var coinbaseCoins = response
            global.chartVal.dataJson['coinbase'] = response;
            global.exchanger.coinbase = true
          })

        CoinService.krakenGetCoinAsync()
          .then(response => {
            var krakenCoins = response
            global.chartVal.dataJson['kraken'] = response;
            global.exchanger.kraken = true
          })

        CoinService.gateGetCoinAsync()
          .then(response => {
            var gateCoins = response
            global.chartVal.dataJson['gate'] = response;
            global.exchanger.gate = true
          })
      })
      .catch(error => {
        console.log(error)
      })
    },
    refreshGoogleChartSlow (global) {
      var krakenCoins;
      var gateCoins;
      var coinOneCoins;
      var refreshCount = 0;

      if (global.exchanger.coinOne) {
        CoinService.coinOneGetCoinAsync()
          .then(response => {
            refreshCount++;
            global.chartVal.dataJson['coinOne'] = response;
          })
      }
      if (global.exchanger.kraken) {
        CoinService.krakenGetCoinAsync()
          .then(response => {
            refreshCount++;
            global.chartVal.dataJson['kraken'] = response;
          })
      }
      if (global.exchanger.gate) {
        CoinService.gateGetCoinAsync()
          .then(response => {
            refreshCount++
            global.chartVal.dataJson['gate'] = response;
          })
      }
    },

    refreshGoogleChartFast (global) {
      var upbitCoins
      var bitthumbCoins
      var houbiCoins
      var binanceCoins
      var coinbaseCoins
      var refreshCount = 0
      if (global.exchanger.upbit) {
        CoinService.upbitGetCoinsAsync()
          .then(response => {
            global.chartVal.dataJson['upbit'] = response;
            refreshCount++
          })
      }
      if (global.exchanger.houbi) {
        CoinService.houbiGetCoinAsync()
          .then(response => {
            refreshCount++;
            global.chartVal.dataJson['houbi'] = response;
          })
      }
      if (global.exchanger.binance) {
        CoinService.binanceGetCoinAsync()
          .then(response => {
            refreshCount++;
            global.chartVal.dataJson['binance'] = response.Json;
            // global.KRWUSD = response.rate;
          })
      }
      if (global.exchanger.bitthumb) {
        CoinService.bitthumbGetCoinAsync()
          .then(response => {
            refreshCount++;
            global.chartVal.dataJson['bitthumb'] = response;
          })
      }
      if (global.exchanger.coinbase) {
        CoinService.coinbaseGetCoinAsync()
          .then(response => {
            refreshCount++
            global.chartVal.dataJson['coinbase'] = response;
          })
      }
    },
    async googleChartPivot (global) {
    }
  },
  created () {


      this.getCoinInfo();
      this.getCurrencyRate();

      this.chartRefresherSlow = setInterval(this.refreshGoogleChartSlow, 30000, this)
      this.chartRefresherFast = setInterval(this.refreshGoogleChartFast, 5000, this)
    
      this.chartRefreshPivot = setInterval(this.googleChartPivot, 5000, this)
      this.getKRWUSD = setInterval(this.getCurrencyRate, 110000, this)

  },
  computed: {    
  },
  beforeUnmount (){
      clearInterval(this.chartRefresherSlow);
      clearInterval(this.chartRefresherFast);
      clearInterval(this.chartRefreshPivot);
      clearInterval(this.getKRWUSD);
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}


</style>
