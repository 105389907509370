<template>
  READ READ READ READ READ 
  {{id}}
</template>

<script>


export default {
  name: 'BBSRead',
  props: ['id'],
  data (){
    return {
    }
  },
  created (){
  },
  methods: {
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
