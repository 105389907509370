<template>
  <div class="home">
    코인 커뮤니티 글들
    <CommunityTable/>

  </div>
</template>

<script>
// @ is an alias to /src
import CommunityTable from '@/components/CommunityTable.vue'

export default {
  name: 'Community',
  components: {
    CommunityTable
  }
}
</script>
