import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Arbitrage from '../views/Arbitrage.vue'
import Community from '../views/Community.vue'
import News from '../views/News.vue'
import NotFound from '../views/NotFound.vue'
import BBSLayout from '../views/bbs/Layout.vue'
import BBSList from '../views/bbs/List.vue'
import BBSRead from '../views/bbs/Read.vue'
import BBSWrite from '../views/bbs/Write.vue'
import BBSModify from '../views/bbs/Modify.vue'
import BBSDelete from '../views/bbs/Delete.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/arbitrage',
    name: 'Arbitrage',
    component: Arbitrage
  },
  {
    path: '/community',
    name: 'Community',
    component: Community
  },
  {
    path: '/news',
    name: 'News',
    component: News
  },
  {
    path: '/bbs',
    name: 'BBSLayout',
    props: true,
    // inheritAttrs: false,
    component: BBSLayout,
    children: [
      {
        path:'',
        name: 'BBSList',
        component: BBSList
      },
      {
        path:'write',
        name: 'BBSWrite',
        component: BBSWrite
      },
      {
        path:':id',
        name: 'BBSRead',
        // props: true,
        // inheritAttrs: false,
        component: BBSRead,
        children: [
          {
            path:'modify',
            name: 'BBSModify',
            component: BBSModify
          },
          {
            path:'delete',
            name: 'BBSDelete',
            component: BBSDelete
          }
        ]
      },
    ]
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
