<template>
  <div class="container">

  <div class="table-wrap horizontal" id='article-table'>
    <div class="table-header">
      <div class="table-header-item article-number">No.</div>
      <div class="table-header-item article-ref">출처</div>
      <div class="table-header-item article-title">제목</div>
      <div class="table-header-item article-writer">작성자</div>
      <div class="table-header-item article-time">파싱시각 (2분간격)</div>
    </div>
    <div v-for="(content, index) in tableContents" :key=index class="table-row"
    v-on:click="sendTo(index)">
      <div class="table-row-item article-number" :id="'table-row-id-' + index">
        {{content.articleId.N}}
      </div>
      <div class="table-row-item article-ref" :id="'table-row-ref-' + index">
        {{content.articleFrom.S}}
      </div>
      <div class="table-row-item article-title" :id="'table-row-title-' + index">
        {{content.articleTitle.S}}
      </div>
      <div class="table-row-item article-writer" :id="'table-row-writer-' + index">
        {{content.writer.S}}
      </div>
      <div class="table-row-item article-time" :id="'table-row-time-' + index">
        <time :date="content.datetime.S">{{this.timeParse(content.datetime.S)}} </time>
      </div>
    </div>
    <LoadingAnimation></LoadingAnimation>

  </div>

  </div>
</template>

<script>

import Community from '@/services/Community.js'

import CustomMath from '@/services/CustomMath.js'
import styles from "@/assets/css/style.css"
import { watchEffect } from 'vue'

import lodash from 'lodash';

export default {
  name: 'CommunityTable',
  components: {
  },
  data () {
    return {
      tableContents: [],
      lastContent: null,
    }
  },
  props: {

  },
  mounted () {
        this.handleScroll = lodash.throttle(this.checkBottom, 1000)
        window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    checkBottom (event) {
      let isBottom = (document.documentElement.scrollTop + window.innerHeight + 200 > document.documentElement.offsetHeight);
      if(isBottom){
        this.getNews();
      }
      // Any code to be executed when the window is scrolled
    },
    sendTo(n) {
      window.open(this.tableContents[n].url.S, '_blank').focus();
    },
    getNews () {
      var global = this;
      this.$store.state.isLoading = true;
      Community.getCrawledArticles(global.lastContent)
      .then(response => {
        for (var i=0; i < response.length; i++){
          global.tableContents.push(response[i]);
        }
        this.$store.state.isLoading = false;
        global.lastContent = global.tableContents.at(-1).articleId.N;
        // console.log(global.tableContents.at(-1).articleId)
      })
    },
    timeParse (articleTime){
      const newArticleTime = articleTime.slice(0,4) + "-" + articleTime.slice(4,6) + "-" + articleTime.slice(6)
      const _now = Date.now();
      const _that = Date.parse(newArticleTime)
      const _diff = _now - _that;

      const diffSeconds = parseInt(_diff/1000);

      const gapHours = parseInt(diffSeconds/3600)
      const gapMin = parseInt((diffSeconds%3600)/60)
      const gapSec = (diffSeconds%3600)%60

      var gapHM = '';
      
      if(gapHours > 0) {
        gapHM = gapHM + gapHours + "시간 전";
        return gapHM
      }
      if (gapMin > 0) {
        gapHM = gapHM + gapMin + "분 전";
        return gapHM
      }
        gapHM = gapHM + gapSec + "초 전";
        return gapHM


    }

  },
  created () {
    this.getNews();

  },
  computed: {    
  },
  beforeUnmount (){
    window.removeEventListener('scroll', this.handleScroll);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}


</style>
