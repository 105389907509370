import moment from "moment";

export default {
      FormatPrice(pair, KRWUSD) {//환율계산, 자릿수맞추기
          let returnVal = '-';
          let value;
          try {
            if (pair['currency']==='KRW'){
              value = pair.v;
            } else if (pair['currency']==='USD') {
              value = pair.v * KRWUSD;
            } else {
              console.log('nopair: ', pair);
            }
    
            if (value) {
              if (value > 100000) {
  
                returnVal = this.numberWithCommas((Math.round(value/1000)*0.1).toFixed(1)) + '만'
              }else if (value > 10000) {
                returnVal = this.numberWithCommas(Math.round(value))
              } else {
              returnVal = this.numberWithCommas((Math.round(value*100)/100));
              }
    
            }
          }
          catch (e) {
            if (e instanceof TypeError ){ //error from call non-exixting coin
    
            } else{
              console.log(e);
            }
          }
            return returnVal;
        },
    sortTable(n) {
      
        var table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
        let xVal, yVal;
        table = document.getElementById("coin-table");
        switching = true;
        // Set the sorting direction to ascending:
        dir = "asc";
        /* Make a loop that will continue until
        no switching has been done: */
        while (switching) {
        // Start by saying: no switching is done:
        switching = false;
        rows = table.getElementsByClassName('table-row');
        /* Loop through all table rows (except the
        first, which contains table headers): */
        for (i = 0; i < (rows.length - 1); i++) {
            // Start by saying there should be no switching:
            shouldSwitch = false;
            /* Get the two elements you want to compare,
            one from current row and one from the next: */
            x = rows[i].getElementsByClassName('table-row-item')[n+1];
            y = rows[i+1].getElementsByClassName('table-row-item')[n+1];
            /* Check if the two rows should switch place,
            based on the direction, asc or desc: */

            xVal = x.innerHTML;
            yVal = y.innerHTML;

            
            // if (xVal==='-') {
            //     xVal = 0;
            // } else {
            //     xVal = xVal.replace(',','').replace('만','0000');
            // }
            
            // if (yVal==='-') {
            //     yVal = 0;
            // } else {
            //     yVal = yVal.replace(',','').replace('만','0000');

            // }

            if (xVal.slice(-1)=='만') {
              xVal = xVal.replace(',','').slice(0,-1)*10000;
            } else if (xVal==='-'){
              xVal = 0;
            } else {
                xVal = xVal.replace(',','')
            }
            if (yVal.slice(-1)=='만') {
              yVal = yVal.replace(',','').slice(0,-1)*10000;
            } else if (yVal==='-'){
              yVal = 0;
            } else {
                yVal = yVal.replace(',','')
            }

            xVal = parseFloat(xVal);
            yVal = parseFloat(yVal);
            
            if (dir == "asc") {
            if (xVal > yVal) {
                // If so, mark as a switch and break the loop:
                shouldSwitch = true;
                break;
            }
            } else if (dir == "desc") {
            if (xVal < yVal) {
                // If so, mark as a switch and break the loop:
                shouldSwitch = true;
                break;
            }
            }

        }
        if (shouldSwitch) {
            /* If a switch has been marked, make the switch
            and mark that a switch has been done: */
            rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
            switching = true;
            // Each time a switch is done, increase this count by 1:
            switchcount ++;
        } else {
            /* If no switching has been done AND the direction is "asc",
            set the direction to "desc" and run the while loop again. */
            if (switchcount == 0 && dir == "asc") {
            dir = "desc";
            switching = true;
            }
        }
        }
 
    },
    sortTablebyTime() {
      
      var table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
      let xVal, yVal;
      table = document.getElementById("news-table");
      switching = true;
      // Set the sorting direction to ascending:
      dir = "desc";
      /* Make a loop that will continue until
      no switching has been done: */
      while (switching) {
      // Start by saying: no switching is done:
      switching = false;
      rows = table.getElementsByClassName('table-row');
      /* Loop through all table rows (except the
      first, which contains table headers): */
      for (i = 0; i < (rows.length - 1); i++) {
          // Start by saying there should be no switching:
          shouldSwitch = false;
          /* Get the two elements you want to compare,
          one from current row and one from the next: */
          x = rows[i].getElementsByClassName('article-time')[0];
          y = rows[i+1].getElementsByClassName('article-time')[0];
          /* Check if the two rows should switch place,
          based on the direction, asc or desc: */

          xVal = moment(x.innerText);
          yVal = moment(y.innerText);

          
          if (dir == "asc") {
          if (xVal > yVal) {
              // If so, mark as a switch and break the loop:
              shouldSwitch = true;
              break;
          }
          } else if (dir == "desc") {
          if (xVal < yVal) {
              // If so, mark as a switch and break the loop:
              shouldSwitch = true;
              break;
          }
          }

      }
      if (shouldSwitch) {
          /* If a switch has been marked, make the switch
          and mark that a switch has been done: */
          rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
          switching = true;
          // Each time a switch is done, increase this count by 1:
          switchcount ++;
      } else {
          /* If no switching has been done AND the direction is "asc",
          set the direction to "desc" and run the while loop again. */
          if (switchcount == 0 && dir == "asc") {
          dir = "desc";
          switching = true;
          }
      }
      }

  },
    numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    numberCommaFixing(x) {
      if (x > 10000){
        x = x.toFixed(0);
      }else{
        x = x.toFixed(2)
      }
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
   }
