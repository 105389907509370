<template>
  DELETE DELETE DELETE DELETE 
</template>

<script>


export default {
  name: 'BBSDelete',
  props: {
  },
  data (){
    return {
    }
  },
  methods: {
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
