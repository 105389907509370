<template>
  <div class="container">

  <div class="table-wrap horizontal headless-table" id='news-table' >
    <!-- <div class="table-header" style="visible:none">
      <div class="table-header-item article-ref">출처</div>
      <div class="table-header-item article-title">제목</div>
      <div class="table-header-item article-time">게재일자</div>
    </div> -->
    <div v-for="(content, index) in tableContents.slice(0,40*pageMultiply)" 
    :key=index class="table-row"
    v-on:click="sendTo(index)" ref='rows'> 
      <div class="table-row-item article-ref" :id="'table-row-ref-' + index">
        {{content.articleFrom}}
      </div>
      <div class="table-row-item article-title" :id="'table-row-title-' + index">
        <p class="ellipsis-1" v-html="content.articleTitle"/>
      </div>

      <div class="table-row-item article-time" :id="'table-row-time-' + index">
        <time :date="content.datetime">{{content.datetime}} </time>
      </div>
      <div class="table-row-item description" :id="'table-row-desc-' + index">
        <p v-html="content.description"></p>
      </div>
      <div class="table-row-item thumbnail" :id="'table-row-thumbnail-' + index">
      <img v-if="content.thumbnail" :src="content.thumbnail" alt="썸넬">
      <img v-if="!content.thumbnail" src="thumbnail.png" alt="썸넬">
      </div>
    </div>
  </div>
    <LoadingAnimation></LoadingAnimation>

  </div>
</template>

<script>

import Community from '@/services/Community.js'

import CustomMath from '@/services/CustomMath.js'
import styles from "@/assets/css/style.css"
import { watch, watchEffect, ref, reactive} from 'vue'
import TableSorting from '@/assets/js/TableSorting.js'

import moment from "moment";

import lodash from 'lodash';

export default {
  name: 'CommunityTable',
  components: {
  },
  setup(){

  },
  data () {
    return {
      nextKey: null,
      pageMultiply: 1,
      tableContents: [],
    }
  },
  props: {

  },
  created (){
  },
  mounted () {
        this.handleScroll = lodash.throttle(this.checkBottom, 2000)
        window.addEventListener('scroll', this.handleScroll);
  },
  updated () {
    // TableSorting.sortTablebyTime();
    this.tableContents = this.GetSortedArray(this.tableContents);
    
  },
  methods: {
    GetSortedArray(array){
    array.sort(function (a,b) {
      var timeA = moment(a['datetime'])
      var timeB = moment(b['datetime'])
      if (timeA < timeB) {    
        return 1;    
      } else if (timeA > timeB) {    
        return -1;    
      }    
        return 0;   
    }); //Pass the attribute to be sorted on   
    
    return array; 
    },
    checkBottom(event) {
      let isBottom = (document.documentElement.scrollTop + window.innerHeight + 200 > document.documentElement.offsetHeight);
      if(isBottom){
        // console.log('bottom')
        this.pageMultiply++;
      }
    },
    sendTo(n) {
      window.open(this.tableContents[n].url, '_blank').focus();
    },
    getNews () {
      var global = this;
      this.$store.state.isLoading = true;

      var keywords = ['암호화폐', '가상화폐', '비트코인', '이더리움', '바이낸스', 'NFT']
      for (var keyNo=0; keyNo < keywords.length; keyNo++){
      Community.getNaverNews(keywords[keyNo])
      .then(response => {
        var parser = new DOMParser();
        var xmlDoc = parser.parseFromString(response,"text/xml");

        var items = xmlDoc.getElementsByTagName("item");
        var isNew = true;
        for (var i=0; i < items.length; i++){
          this.tableContents.forEach(function(content){
            if(items[i].getElementsByTagName('link')[0].childNodes[0].nodeValue===content['url']){
              isNew = false;
            }
          })

          if(isNew){
            try{
              this.tableContents.push({
              'articleTitle': items[i].getElementsByTagName('title')[0].childNodes[0].nodeValue,
              'articleFrom': items[i].getElementsByTagName('author')[0].childNodes[0].nodeValue,
              'url': items[i].getElementsByTagName('link')[0].childNodes[0].nodeValue,
              'datetime': moment(items[i].getElementsByTagName('pubDate')[0].childNodes[0].nodeValue, 'ddd, DD MMM YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
              // moment('Mon Jan 24 2022 17:00:00 GMT+0900', 'ddd MMM DD YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
              'description': items[i].getElementsByTagName('description')[0].childNodes[0].nodeValue,
              'thumbnail': items[i].getElementsByTagName('media:thumbnail')[0].getAttribute('url'),
              })
            }
            catch{}
          }
        }
        this.$store.state.isLoading = false;

        // this.tableContents = this.GetSortedArray(this.tableContents);

      })

      let googleDescPat = /\>.*\<\/a/;
      Community.getGoogleNews(keywords[keyNo])
      .then(response => {
        var parser = new DOMParser();
        var xmlDoc = parser.parseFromString(response,"text/xml");

        var items = xmlDoc.getElementsByTagName("item");
        var isNew = true;
        for (var i=0; i < items.length; i++){
          this.tableContents.forEach(function(content){
            if(items[i].getElementsByTagName('link')[0].childNodes[0].nodeValue===content['url']){
              isNew = false;
            }
          })

          if(isNew){
            try{

              var desc = items[i].getElementsByTagName('description')[0].childNodes[0].nodeValue;
              var googleDescription = desc.match(googleDescPat)[0].slice(1,-3)


              this.tableContents.push({
              'articleTitle': items[i].getElementsByTagName('title')[0].childNodes[0].nodeValue,
              'articleFrom': items[i].getElementsByTagName('source')[0].childNodes[0].nodeValue,
              'url': items[i].getElementsByTagName('link')[0].childNodes[0].nodeValue,
              'datetime': moment(items[i].getElementsByTagName('pubDate')[0].childNodes[0].nodeValue).format('YYYY-MM-DD HH:mm:ss'),
//  moment.utc('Fri, 04 Feb 2022 08:15:03 GMT').tz('Asia/Seoul').format('YYYY-MM-DD HH:mm:ss')
//  moment('Fri, 04 Feb 2022 08:15:03 GMT','ddd, DD MMM YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
              'description': googleDescription,
              // 'thumbnail': items[i].getElementsByTagName('media:thumbnail')[0].getAttribute('url'),
              })
            }
            catch{}
          }
        }
        // this.tableContents = this.GetSortedArray(this.tableContents);
      })


      }
    },
    timeParse (articleTime){
      const newArticleTime = articleTime.slice(0,4) + "-" + articleTime.slice(4,6) + "-" + articleTime.slice(6)
      const _now = Date.now();
      const _that = Date.parse(newArticleTime)
      const _diff = _now - _that;

      const diffSeconds = parseInt(_diff/1000);

      const gapHours = parseInt(diffSeconds/3600)
      const gapMin = parseInt((diffSeconds%3600)/60)
      const gapSec = (diffSeconds%3600)%60

      var gapHM = '';
      
      if(gapHours > 0) {
        gapHM = gapHM + gapHours + "시간";
      }
      if (gapMin < 0) {
        gapHM = gapHM + gapMin + "분";
      }

        gapHM = gapHM + gapSec + "초 전";

      
      return gapHM
    


    }

  },
  created () {
    this.getNews();

  },
  computed: {    

  },
  beforeUnmount (){
    window.removeEventListener('scroll', this.handleScroll);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}


</style>
