<template>
  <div class="home">
    코인 늬-우-스
    <NewsTable/>
  </div>
</template>

<script>
// @ is an alias to /src
import NewsTable from '@/components/NewsTable.vue'

export default {
  name: 'News',
  components: {
    NewsTable
  }
}
</script>
