<template>
  <div class="home">
    <div class="interface-bar" v-if="needWrite">
    <form class="search-form" >

      <input/>
      <div class="button">search</div>
    </form>
    <router-link class="button" :to="{name: 'BBSWrite'}">write</router-link>
    <!-- <router-link class="button" :to="{name: 'BBSRead'}">read</router-link> -->
    </div>
  <router-view :key="$route.path"/>
  </div>
  <LoadingAnimation></LoadingAnimation>

</template>

<script>
// @ is an alias to /src

import Community from '@/services/Community.js'

export default {
  name: 'BBSLayout',
  props: ['id'],
  components: {
  },
  data () {
    return {
      // tableContents: [],
      // lastContent: null,
    }
  },
  methods: {
  },
  computed: {
    needWrite () {
      return this.$route.name==='BBSList' 
    }
  },
  created () {    
    this.$store.state.bbsContents.lastContent = null;
    this.$store.state.bbsContents.tableContents = []
    this.$store.dispatch("getBbsList")

    // console.log('created')
  },
  activated () {
    // console.log('activate')
    
  }  ,
}
</script>