<template>
  <div>
    <!-- <img class="loading-blue" :src="loadingAnimation"/> -->
    <img v-if="this.$store.state.isLoading" class="loading-blue" :src="loadingAnimation"/>
  </div>
</template>

<script>
import loadingAnimation from '@/assets/svg/animations/loading.svg'

export default {
  name: 'LoadingAnimation',
  data () {
      return {
      loadingAnimation: loadingAnimation

      }
  },
  props: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
