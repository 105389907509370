import axios from 'axios'
const upbitAPI = axios.create({

  baseURL: 'https://api.upbit.com/v1/',
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }

})
const houbiAPI = axios.create({
  baseURL: 'https://api.huobi.pro/market/',
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})
const bitthumbAPI = axios.create({
  baseURL: 'https://api.bithumb.com/public/',
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})
const getAPI = axios.create({
  baseURL: process.env.VUE_APP_API,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})
const apiServer = axios.create({
  baseURL: process.env.VUE_APP_COIN_API,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})
const binanceAPI = axios.create({
  baseURL: 'https://api.binance.com/api/v3/',
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})
const coinbaseAPI = axios.create({
  baseURL: 'https://api.coinbase.com/v2/',
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

export default {
  async getUSDRate() {
    return await getAPI.get('/coin/rate/');
  },
  async upbitGetMarketList () {
    const response = await upbitAPI.get('/market/all')
    const upbitMarketList = response.data
    var parsedMarketList = '' // initialize market list to get
    var market = ''
    for (var i = 0; i < upbitMarketList.length; i++) {
      market = upbitMarketList[i].market
      if (market.slice(0, 4) === 'KRW-') {
        parsedMarketList = parsedMarketList + ',' + market
      }
    }
    parsedMarketList = parsedMarketList.slice(1)
    return parsedMarketList
  },
  async upbitGetCurrPrices (marketList) {
    const response = await upbitAPI.get('/ticker' + '?markets=' + encodeURIComponent(marketList))
    var upbitAllPrice = response.data
    // console.log(upbitAllPrice);
    // create upbit json
    var upbitJsonPrice = {}
    var eachCoin = ''
    for (var i = 0; i < upbitAllPrice.length; i++) {
      eachCoin = upbitAllPrice[i]
      upbitJsonPrice[eachCoin.market.slice(4).toUpperCase()] = {'v': parseFloat(eachCoin.trade_price), 'currency':'KRW'}
    }
    return upbitJsonPrice
  },
  async upbitGetCoinsAsync () {
    const upbitMarketList = await this.upbitGetMarketList()
    const upbitJsonPrice = await this.upbitGetCurrPrices(upbitMarketList)
    return upbitJsonPrice
  },
  // houbi
  async houbiGetCoinAsync () {
    const response = await houbiAPI.get('/tickers')
    const houbiJsonPrice = await this.houbiGetCurrPrices(response.data.data)
    return houbiJsonPrice
  },
  async houbiGetCurrPrices (houbiPriceBulk) {
    const houbiJsonPrice = {}
    var eachCoin
    for (var i = 0; i < houbiPriceBulk.length; i++) {
      eachCoin = houbiPriceBulk[i]
      if (eachCoin.symbol.slice(-4) === 'usdt') {
        houbiJsonPrice[eachCoin.symbol.slice(0, -4).toUpperCase()] = {'v': parseFloat(eachCoin.close), 'currency': 'USD'}
      }
    }

    return houbiJsonPrice
  },
  // coinOne
  async coinOneGetCoinAsync () {
    const response = await apiServer.get('/coinone/')
    const coinOneJsonPrice = await this.coinOneGetCurrPrices(response.data)
    return coinOneJsonPrice
  },
  async coinOneGetCurrPrices (coinOnePriceBulk) {
    const coinOneJsonPrice = {}
    var eachCoin
    const keys = Object.keys(coinOnePriceBulk)
    for (var i = 0; i < keys.length; i++) {
      eachCoin = keys[i]
      if (!(['result', 'errorCode', 'timestamp'].includes(eachCoin))) {
        coinOneJsonPrice[eachCoin.toUpperCase()] = {'v': parseFloat(coinOnePriceBulk[eachCoin].last), 'currency': 'KRW'}
      }
    }
    return coinOneJsonPrice
  },
  // bitthumb
  async bitthumbGetCoinAsync () {
    const response = await bitthumbAPI.get('/ticker/ALL_KRW')
    const bitthubJsonPrice = await this.bitthumbGetCurrPrices(response.data.data)
    return bitthubJsonPrice
  },
  async bitthumbGetCurrPrices (bitthumbPriceBulk) {
    const bitthubJsonPrice = {}
    var eachCoin
    const keys = Object.keys(bitthumbPriceBulk)
    for (var i = 0; i < keys.length; i++) {
      eachCoin = keys[i]
      if (!(['date'].includes(eachCoin))) {
        bitthubJsonPrice[eachCoin.toUpperCase()] = {'v': parseFloat(bitthumbPriceBulk[eachCoin].closing_price), 'currency': 'KRW'}
      }
    }
    return bitthubJsonPrice
  },
  // binance
  async binanceGetCoinAsync () {
    const response = await binanceAPI.get('/ticker/price')
    const binanceJsonPrice = await this.binanceGetCurrPrices(response.data)
    return binanceJsonPrice
  },
  async binanceGetCurrPrices (binancePriceBulk) {
    const binanceJsonPrice = {};
    var KRWUSD;
    var eachCoin;
    for (var i = 0; i < binancePriceBulk.length; i++) {
      eachCoin = binancePriceBulk[i].symbol
      if (eachCoin.slice(-4)==='USDT') {
        binanceJsonPrice[eachCoin.slice(0, -4).toUpperCase()] = {'v': parseFloat(binancePriceBulk[i].price), 'currency': 'USD'}
      } else if (eachCoin==='BUSDBKRW') { //USD to KRW
        KRWUSD = parseFloat(binancePriceBulk[i].price);
      }
    }

    var ret = {
      'Json': binanceJsonPrice,
      'rate': KRWUSD
    }

    return ret
  },
  // coinbase
  async coinbaseGetCoinAsync () {
    const response = await coinbaseAPI.get('/exchange-rates' + '?currency=' + encodeURIComponent('krw'))
    let  coinbaseJsonPrice;
    let KRWUSD;
    return await this.coinbaseGetCurrPrices(response.data.data.rates)
  },
  async coinbaseGetCurrPrices (coinbasePriceBulk) {
    const coinbaseJsonPrice = {}
    var eachCoin
    const keys = Object.keys(coinbasePriceBulk)
    for (var i = 0; i < keys.length; i++) {
      eachCoin = keys[i]
      coinbaseJsonPrice[eachCoin.toUpperCase()] = {'v': 1 / parseFloat(coinbasePriceBulk[eachCoin]), 'currency': 'KRW'}
    }
    return coinbaseJsonPrice
  },
  // kraken
  async krakenGetCoinAsync () {
    const response = await apiServer.get('/kraken/AssetPairs')
    const krakenPairList = await this.krakenMakePairList(response.data.result)
    const response2 = await apiServer.get('/kraken/Ticker' + '?pair=' + encodeURIComponent(krakenPairList))
    const krakenJsonPrice = await this.krakenGetCurrPrices(response2.data.result)
    return krakenJsonPrice
  },
  async krakenMakePairList (krakenMarketList) {
    var parsedMarketList = '' // initialize market list to get

    var keys = Object.keys(krakenMarketList)

    for (var i = 0; i < keys.length; i++) {
      if (keys[i].slice(-4) === 'USDT') {
        parsedMarketList = parsedMarketList + ',' + keys[i]
      }
    }
    parsedMarketList = parsedMarketList.slice(1)
    return parsedMarketList
  },
  async krakenGetCurrPrices (krakenPriceBulk) {
    const krakenJsonPrice = {}
    var eachCoin
    const keys = Object.keys(krakenPriceBulk)
    let coinTicker
    for (var i = 0; i < keys.length; i++) {
      eachCoin = keys[i]
      if (eachCoin.slice(-4) === 'USDT') {
        coinTicker = eachCoin.slice(0, -4).toUpperCase()
        if (coinTicker === 'XBT') {
          coinTicker = 'BTC'
        }
        krakenJsonPrice[coinTicker] = {'v': parseFloat(krakenPriceBulk[eachCoin].o), 'currency': 'USD'}
      }
    }
    return krakenJsonPrice
  },
  // gate
  async gateGetCoinAsync () {
    const response = await apiServer.get('/gate/spot/tickers')
    const gateJsonPrice = await this.gateGetCurrPrices(response.data)
    return gateJsonPrice
  },
  async gateGetCurrPrices (gatePriceBulk) {
    const gateJsonPrice = {}
    var eachCoin
    for (var i = 0; i < gatePriceBulk.length; i++) {
      eachCoin = gatePriceBulk[i].currency_pair
      if (eachCoin.slice(-4) === 'USDT') {
        gateJsonPrice[eachCoin.slice(0, -5).toUpperCase()] = {'v': parseFloat(gatePriceBulk[i].last), 'currency': 'USD'}
      }
    }
    return gateJsonPrice
  }

}
