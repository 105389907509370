import { createStore } from 'vuex'
import Community from '@/services/Community.js'
import CustomMath from '@/services/CustomMath.js'

export default createStore({
  state: {
    bbsContents: {
      tableContents: [],
      lastContent: null,
    },
    isLoading: null,

  },
  mutations: {
    community_ADD_CONTENT(state, content){
      state.bbsContents.tableContents.push(content);
      state.bbsContents.lastContent = content.articleId.N;
      // console.log('commit')
    }
  },
  actions: {
    async getBbsList ({ commit }) {
      this.state.isLoading = true;
      Community.getBbsList(this.state.bbsContents.lastContent)
      .then(response => {
        for (var i=0; i < response.length; i++){
          response[i]['timegap'] = CustomMath.timeParse(response[i].articleId.N);
          this.commit('community_ADD_CONTENT', response[i]);
        }
      this.state.isLoading = false;
    })

    },
    async getBbsArticle ({ commit }, payload) {
      this.state.isLoading = true;
      console.log(payload)
      // Community.getBbsArticle(this.state.bbsContents.lastContent)
      // .then(response => {
      //   for (var i=0; i < response.length; i++){
      //     response[i]['timegap'] = CustomMath.timeParse(response[i].articleId.N);
      //     this.commit('community_ADD_CONTENT', response[i]);
      //   }
      this.state.isLoading = false;
    // })

    },
  },
  modules: {
  }
})
