<template>
  <div class="home">
    <CoinTable/>
  </div>
</template>

<script>
// @ is an alias to /src
import CoinTable from '@/components/CoinTable.vue'

export default {
  name: 'Arbitrage',
  components: {
    CoinTable
  }
}
</script>
