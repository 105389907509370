
export default {
  async parseJsonToTablePivot (dataJson) {
    var exchangeKeys = Object.keys(dataJson);
    let googleData = {
        "cols": [
            //declar ticker column
            {
                "id": "",
                "label": "ticker",
                // "pattern": "",
                "type": "string"
            },

        ],
        "rows": []

    };

    //upbit 기준으로 get coin tickers as key
    var coinKeys = Object.keys(dataJson['upbit']);
    var _currExchange, _currCoin;

    ////
    
    //i - coinKey, j - exchangeKey double loop
    //coinKey
    for(var i=0; i < coinKeys.length; i++ ){

        _currCoin = coinKeys[i];

        var c = []; //declar new line for each coin

        c.push({'v': _currCoin});

        //exchange loops
        for(var j=0; j < exchangeKeys.length; j++){
            _currExchange = exchangeKeys[j];

            if (i===0){
                //declar exchanger column at first loop
                googleData["cols"].push(
                    {
                        "id": "",
                        "label": _currExchange,
                        "pattern": "###,###,###,###",
                        "type": "number"
                    }
                )
            }
            _currExchange = exchangeKeys[j];

            if (dataJson[_currExchange][_currCoin]==='undenfined'){
                c.push({}) // add empty {}
                //pass exchange if doesnt have specific coin
            }else{
                //{v: value}
                c.push(
                    {'v': dataJson[_currExchange][_currCoin]}
                );
            }
        }
        googleData.rows.push({'c': c});

    }
    return googleData;
    },
    async parseJsonToTable (dataJson) {
        var exchangeKeys = Object.keys(dataJson);
        let googleData = {
            "cols": [
                //declar ticker column
                {
                    "id": "",
                    "label": "exchange",
                    // "pattern": "",
                    "type": "string"
                },
    
            ],
            "rows": []
    
        };
    
        //upbit 기준으로 get coin tickers as key
        var coinKeys = Object.keys(dataJson['upbit']);
        var _currExchange, _currCoin;
    
        ////
        
        //i - exchangeKey, j - coinKey double loop
        //exchangeKey
        for(var i=0; i < exchangeKeys.length; i++ ){
    
            _currexchange = exchangeKeys[i];
    
            var c = []; //declar new line for each exchange
    
            c.push({'v': _currexchange});
    
            //exchange loops
            for(var j=0; j < coinKeys.length; j++){
                _currcoin = coinKeys[j];
    
                if (i===0){
                    //declar coinr column at first loop
                    googleData["cols"].push(
                        {
                            "id": "",
                            "label": _currcoin,
                            "pattern": "###,###,###,###",
                            "type": "number"
                        }
                    )
                }
                _currcoin = coinKeys[j];
    
                if (dataJson[_currExchange][_currCoin]==='undenfined'){
                    c.push({}) // add empty {}
                    //pass exchange if doesnt have specific coin
                }else{
                    //{v: value}
                    c.push(
                        {'v': dataJson[_currExchange][_currCoin]}
                    );
                }
            }
            googleData.rows.push({'c': c});
    
        }
        return googleData;
    
        ////
        //exchangeKeys, coinKeys double for loop
        //exchange loop
        for(var i=0; i < exchangeKeys.length; i++ ){
    
            _currExchange = exchangeKeys[i];
            
            //declar exchanger column
            googleData["cols"].push(
                {
                    "id": "",
                    "label": _currExchange,
                    // "pattern": "",
                    "type": "number"
                }
            )
    
            
            var c; //declare new line as a list
    
            //coin loops
            for(var j=0; j < coinKeys.length; j++){
                c = [];
                if (dataJson[_currExchange][coinKeys[j]]==='undenfined'){
                    //pass if no specific coin
                }else{
                    //{v: value}
                    c.push(
                        {'v': dataJson[_currExchange][coinKeys[j]]}
                    );
                }
                googleData.rows.push({'c': c});
            }
    
    
        }
        return googleData;
    
    },
    timeParse (miliseconds){
        const _now = Date.now();
        const _diff = _now - miliseconds;
  
        const diffSeconds = parseInt(_diff/1000);
  
        const gapHours = parseInt(diffSeconds/3600)
        const gapMin = parseInt((diffSeconds%3600)/60)
        const gapSec = (diffSeconds%3600)%60
  
        var gapHM = '';
        
        if(gapHours > 0) {
          gapHM = gapHM + gapHours + "시간 전";
          return gapHM
        }
        if (gapMin > 0) {
          gapHM = gapHM + gapMin + "분 전";
          return gapHM
        }
          gapHM = gapHM + gapSec + "초 전";
          return gapHM
      },
}