import { createApp } from 'vue'
import Router from 'vue-router'
import App from './App.vue'
import router from './router'
import store from './store'
import LoadingAnimation from '@/components/LoadingAnimation.vue'

const app = createApp(App);

app.component("LoadingAnimation", LoadingAnimation);
app.use(Router);
app.use(store);
app.use(router);
app.mount("#app")


// import { createApp } from 'vue'
// import App from './App.vue'
// import router from './router'
// import store from './store'

// createApp(App).use(store).use(router).mount('#app');