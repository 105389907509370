<template>
  <div class="container">
    <form class="write-form" @submit.prevent="submit">
      <label class="writer-label">writer</label>
      <input v-model="writer"
      type="text"
      placeholder="writer"
      class="writer">
      <div v-if="errors.writer" class="writer tooltip">{{errors.writer}}</div>

      <label class="password-label">password</label>
      <input v-model="password"
      type="password"
      placeholder="password"
      class="password">
      <div v-if="errors.password" class="password tooltip">{{errors.password}}</div>

      <div class="category">
        <select class="select-box" v-model="category">
          <option>잡담</option>
          <option>정보</option>
          <option>질문</option>
          <option>요청</option>
        </select>
      </div>

      <label class="title-label">Title</label>
      <div class="title">
        <input v-model="title"
        type="text"
        placeholder="Title"
        class="inputfield">
      </div>
      <div v-if="errors.title" class="title tooltip">{{errors.title}}</div>

      <label class="description-label">Description</label>
      <div class="description">
        <textarea v-model="description"
        placeholder="description"
        class="inputfield"/>
      </div>
      <div v-if="errors.description" class="description tooltip">{{errors.description}}</div>



      <button class="submit" type="submit">submit</button>

    </form>
  </div>
</template>

<script>

import Community from '@/services/Community.js'
import { useField, useForm } from 'vee-validate'
import { useRouter } from 'vue-router'

export default {
  name: 'BBSWrite',
  props: {
  },
  setup () {
    
    const required = value => {
      const requiredMessage = '빈 칸을 채워주세요.'
      if (value === undefined || value === null) return requiredMessage
      if (!String(value).length) return requiredMessage
      return true
    }
    const minLength = (number, value) => {
      if (String(value).length < number) return '최소 ' + number + ' 글자를 입력해주세요.'
      return true
    }
    const maxLength = (number, value) => {
      if (String(value).length > number) return '최대 ' + number + ' 글자까지 입력할 수 있습니다.'
      return true
    }
    const anything = () => {
      return true
    }
    const validationSchema = {
      writer: value => {
        const req = required(value)
        if (req !== true) return req
        const min = minLength(2, value)
        if (min !== true) return min
        const max = maxLength(10, value)
        if (max !== true) return max
        return true
      },
      password: value => {
        const req = required(value)
        if (req !== true) return req
        const min = minLength(3, value)
        if (min !== true) return min
        const max = maxLength(100, value)
        if (max !== true) return max
        return true
      },
      category: required,
      title: value => {
        const req = required(value)
        if (req !== true) return req
        const min = minLength(3, value)
        if (min !== true) return min
        const max = maxLength(30, value)
        if (max !== true) return max
        
        return true
      },
      description: value => {
        const req = required(value)
        if (req !== true) return req
        const min = minLength(20, value)
        if (min !== true) return min
        return true
      } 
    }
    const { handleSubmit, errors } = useForm({
      validationSchema,
      initialValues: {
        category: '잡담'
      }
    })
    const { value: category } = useField('category')
    const { value: writer } = useField('writer')
    const { value: password } = useField('password')
    const { value: title } = useField('title')
    const { value: description } = useField('description')
    
    const router = useRouter()
    const submit = handleSubmit(values => {
      Community.postWrite(values)
      router.push({
        name: 'BBSList',
      })
    })

    return {
      category,
      writer,
      password,
      title,
      description,
      submit,
      errors
    }
  },
  data (){
    return {
    }
  },
  methods: {
    // goList(){
    //   console.log(this.errors)
    //   // Community.postWrite(this.article)
    //   alert("글 작성 완료")
    //   // this.$router.push({
    //   //   name: 'BBSList',
    //   // })

    // }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
