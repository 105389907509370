<template>
  <div class="home">
    <HomeDetail msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
// @ is an alias to /src
import HomeDetail from '@/components/HomeDetail.vue'

export default {
  name: 'Home',
  components: {
    HomeDetail
  }
}
</script>
